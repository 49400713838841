<template>
  <div class="logs">
    <template class="not-limit" v-if="show">{{ notLimit }}</template>
    <el-card class="box-card" v-if="!show">
      <el-page-header
        class="product-header"
        @back="goBack"
        content="日志列表"
      ></el-page-header>
      <div class="select-container">
        <div class="product-table">
          <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            :data="tableData"
            border
            style="width: 100%; max-width: 100%"
            :cell-style="csllStyle"
          >
            <el-table-column
              fixed
              type="index"
              :index="pagesize * page_num - pagesize + 1"
              label="序号"
              width="60%"
            >
            </el-table-column>
            <el-table-column prop="user_key" label="用户店铺标识" width="240%">
            </el-table-column>
            <el-table-column prop="username" label="用户名" width="200%">
            </el-table-column>
            <el-table-column prop="userNo" label="用户账号" width="200%">
            </el-table-column>
            <el-table-column prop="province" label="登录省份" width="180%">
            </el-table-column>
            <el-table-column prop="city" label="登录城市" width="230%">
            </el-table-column>
            <el-table-column prop="loginTime" label="登录时间" width="260%">
            </el-table-column>
            <el-table-column prop="rectangle" label="登录IP" width="260%">
            </el-table-column>
          </el-table>
          <!-- 分页底部 -->
          <el-pagination
            @current-change="handleCurrentChange"
            layout="total, prev, pager, next"
            :total="totalNum"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import secret from "@/utils/secret.js";
import axios from "axios";
export default {
  data() {
    return {
      notLimit: "您暂时没有权限查看登陆日志，如需要请联系超管终端人员！",
      show: true,
      tableData: "",
      loading: true,
      currentPage: 1,
      pagesize: 10,
      totalNum: 0,
      page_num: 1,
    };
  },
  created() {
    this.show =
      secret.Decrypt(localStorage.getItem("user_key")) ===
        "yhl001656847198012" &&
      secret.Decrypt(localStorage.getItem("sideMenu")) === 1;
    let view = true;
    let pageNum = 1;
    if (!this.show) {
      axios.post(this.GLOBAL.api + "/logs", { view, pageNum }).then((res) => {
        // console.log("获取日志信息----", res);
        this.tableData = res.data.val;
        this.totalNum = res.data.total[0].count
        this.loading = false;
      });
    }
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    handleCurrentChange(val) {
        this.loading = true;
      let pageNum = val;
      this.page_num = val;
      let view = true;
      axios
        .post(this.GLOBAL.api + "/logs", { view, pageNum })
        .then((res) => {
          this.totalNum = res.data.total[0].count;
          this.tableData = res.data.val;
          this.loading = false;
        });
    },
    csllStyle(row) {
        const rowVal = row.row
        if(rowVal.user_key === null || rowVal.username === null || rowVal.userNo === null || rowVal.province === null || rowVal.city === null || rowVal.loginTime === null) {
            return 'backgroundColor:#e6a23c;color:#FFF'
        }
    }
  },
};
</script>
<style scoped>
.box-card {
    padding: 20px 10px;
}

.select-container {
  margin: 10px;
}
</style>